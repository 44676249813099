<template>
  <!-- Üst Sağ - Kullanıcı Menu Alanı-->
  <div class="lb-nav-content-right pl-2.5">

    <NuxtLinkLocale v-if="status === 'unauthenticated'" href="/login" class="flex flex-col items-center space-y-1">
      <LucideUserCircle class="h-5 w-5 lg:m-0 mx-1.5" />
    </NuxtLinkLocale>
    
    <template v-else-if="status === 'authenticated'">
      <UserDropdownMenu class="flex flex-col items-center space-y-1">
        <template #icon>
          <LucideUser class="size-5" />
        </template>
        <span class="hidden sm:block max-w-[120px] truncate">{{ firstName }}</span>
      </UserDropdownMenu>
    </template>

    <NuxtLinkLocale
        v-if="status === 'authenticated'"
        href="/account/wishlist"
        class="flex-col items-center space-y-1 lg:flex hidden"
    >
      <LucideHeart class="h-5 w-5 lg:m-0 mx-2.5" />
    </NuxtLinkLocale>

    <NuxtLinkLocale
        v-else-if="status === 'unauthenticated'"
        href="/login"
        class="flex-col items-center space-y-1 lg:flex hidden"
    >
      <LucideHeart class="h-5 w-5 lg:m-0 mx-1.5" />
    </NuxtLinkLocale>

    <!-- Sepet Bölümü-->
    <ShoppingCartMenu class="inline group order-6 leading-3" isTopBasketCount="true">
      <LucideShoppingCart class="w-5 h-5 sm:w-4 sm:h-4 lg:h-5 lg:w-5 lg:m-0 mx-2.5" />
    </ShoppingCartMenu>
  </div>
</template>

<script setup>
import ShoppingBag from "~/assets/icons/shopping-bag.svg";
import UserIcon from "~/assets/icons/user.svg";
import { HeartIcon } from "@heroicons/vue/24/outline";

const {status, data} = await useAuth()
const {isWholesaleUser} = await useCustomer()
const firstName = computed(() => (unref(data)?.user?.name).split(' ').slice(0, -1).join(' ') ? (unref(data)?.user?.name).split(' ').slice(0, -1).join(' ') : (unref(data)?.user?.name).split('@').slice(0, -1).join(' '))

</script>

<style lang="scss">
.lb-nav-content-right {
  @apply flex items-center justify-end gap-2 lg:gap-5 order-4 col-span-2;
}
</style>